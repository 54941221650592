import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    component: () => import('@/views/index.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import(/* webpackChunkName: "index" */ '@/views/homePage.vue'),
        meta: { title: '首页', num: 0 }
      },
      {
        path: '/productService',
        component: () => import(/* webpackChunkName: "productService" */ '@/views/product-service.vue'),
        meta: { title: '产业服务', num: 1 }
      },
      {
        path: '/supplyDemand',
        component: () => import(/* webpackChunkName: "supplyDemand" */ '@/views/supply-demand.vue'),
        meta: { title: '供需服务', num: 1 }
      },
      {
        path: '/productServiceList',
        component: () => import(/* webpackChunkName: "productServiceList" */ '@/views/product-service-list.vue'),
        meta: { title: '产业信息/前沿报告', num: 1 }
      },
      {
        path: '/supplyDemandDetail',
        component: () => import(/* webpackChunkName: "supplyDemandDetail" */ '@/views/supply-demand-detail.vue'),
        meta: { title: '供需服务列表详情', num: 1 }
      },
      {
        path: '/list',
        component: () => import(/* webpackChunkName: "list" */ '@/views/list.vue'),
        meta: { title: '政策服务', num: 2 }
      },
      {
        path: '/appealService',
        component: () => import(/* webpackChunkName: "appealService" */ '@/views/appeal-service.vue'),
        meta: { title: '诉求服务', num: 5 }
      },
      {
        path: '/problemListing',
        component: () => import(/* webpackChunkName: "problemListing" */ '@/views/problem-listing.vue'),
        meta: { title: '问题选登', num: 5 }
      },
      {
        path: '/innovativeService',
        component: () => import(/* webpackChunkName: "innovativeService" */ '@/views/innovativeService.vue'),
        meta: { title: '创新服务平台', num: 6}
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


// 切换路由，滚动到最顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

export default router
